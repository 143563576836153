@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    //$fa-font-path: '../../../webfonts';
    //@import "includes/clubster_font";
    //@import "includes/fontawesome/fontawesome.scss";
    //@import "includes/fontawesome/solid.scss";
    //@import "includes/fontawesome/brands.scss";
    //@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Raleway:400,700');

    .clb-label {
        @apply px-2;
        @apply inline-flex;
        @apply text-xs;
        @apply leading-6;
        @apply font-semibold;
        @apply rounded-full;
    }

    .label-attending {
      @apply clb-label;
      @apply bg-green-700;
      @apply text-white;
    }

    .label-waitlist {
      @apply clb-label;
      @apply bg-yellow-700;
      @apply text-white;
    }

/* organization contexts */
    .label-orgcontext {
      @apply clb-label;
      line-height: 1rem!important;
      @apply uppercase;
      position: relative;
      top: -3px;
    }

    .label-orgcontext-personal {
      @apply label-orgcontext;
      background-color: #5243AA !important;
      color: #FFFFFF !important;
      @apply label-bold;
        & > i.fas {
          padding-top:0.1rem;
          padding-right:0.15rem;
        }
    }

    .label-orgcontext-open {
      @apply label-orgcontext;
      background-color: #FFFFFF !important;
      color: #00875A !important;
      @apply border-solid;
      @apply border-1;
      border-color: #00875A !important;
      @apply label-bold;
      & > i.fas {
        padding-top:0.1rem;
        padding-right:0.15rem;
      }
    }

    .label-orgcontext-private {
      @apply label-orgcontext;
      background-color: #B3D4FF !important;
      color: #0052CC !important;
      @apply label-bold;
        & > i.fas {
          padding-top:0.1rem;
          padding-right:0.15rem;
        }
    }

    .label-orgcontext-club {
      @apply label-orgcontext;
      top: 0px!important;
      @apply label-hollow;
      @apply label-bold;
      @apply border-solid;
      @apply border-1;
      border-color: #0052CC !important;
      color: #0052CC !important;
    }

    .label-orgcontext-group {
      @apply label-orgcontext;
      top: 0px!important;
      @apply label-hollow;
      @apply label-bold;
      @apply border-solid;
      @apply border-1;
      @apply border-gray-800;
      @apply text-gray-800;
    }

    .label-orgcontext-shadow {
      box-shadow: 2px 3px 6px #1a1a1a;
    }

/* regular labels */
    .label-info {
        @apply clb-label;
        @apply bg-blue-100;
        @apply text-blue-800;
        .label-border {
            @apply border-blue-800;
        }
    }

    .label-success {
        @apply clb-label;
        @apply bg-green-100;
        @apply text-green-800;
        .label-border {
            @apply border-green-800;
        }
    }

    .label-warning {
        @apply clb-label;
        @apply bg-yellow-100;
        @apply text-yellow-800;
        .label-border {
            @apply border-yellow-800;
        }
    }

    .label-danger {
        @apply clb-label;
        @apply bg-red-100;
        @apply text-red-800;
        .label-border {
            @apply border-red-800;
        }
    }

    .label-disabled {
        @apply clb-label;
        @apply bg-gray-300;
        @apply text-gray-800;
        .label-border {
            @apply border-gray-800;
        }
    }

/* inverted regular labels */
    .label-info-inverted {
        @apply clb-label;
        @apply bg-blue-800;
        @apply text-blue-100;
    }

    .label-success-inverted {
        @apply clb-label;
        @apply bg-green-800;
        @apply text-green-100;
    }

    .label-warning-inverted {
        @apply clb-label;
        @apply bg-yellow-800;
        @apply text-yellow-100;
    }

    .label-danger-inverted {
        @apply clb-label;
        @apply bg-red-800;
        @apply text-red-100;
    }

    .label-disabled-inverted {
        @apply clb-label;
        @apply bg-gray-800;
        @apply text-gray-200;
    }

/* label enhancements: border, hollow, short-height, bold */
    .label-border {
        @apply border-solid;
        @apply border-1;
    }

    .label-hollow {
        background-color: #FFFFFF !important;
    }

    .label-short {
        line-height: 1rem!important;
    }

    .label-bold {
        @apply font-bold;
    }

/* statuses */
    .label-status {
      @apply clb-label;
      line-height: 1rem!important;
      @apply uppercase;
    }

    .label-status-draft {
      @apply label-warning-inverted;
      @apply label-bold;
      @apply label-short;
      @apply uppercase;
    }

    .label-status-pending {
      @apply label-warning;
      @apply label-bold;
      @apply label-short;
      @apply label-border;
      @apply uppercase;
      @apply border-yellow-800;
    }

    .label-status-published {
      @apply label-success-inverted;
      @apply label-bold;
      @apply label-short;
      @apply uppercase;
    }

    .label-status-not-published,
    .label-status-deleted,
    .label-status-suspended,
    .label-status-failed {
      @apply label-danger-inverted;
      @apply label-bold;
      @apply label-short;
      @apply uppercase;
    }

    .label-status-cancelled {
      @apply label-danger;
      @apply label-bold;
      @apply label-short;
      @apply label-border;
      @apply border-red-800;
      @apply uppercase;
    }

/* alternatives for member or contact create.html.twig */

    .label-user-status-revoked { 
      @apply label-warning-inverted;
    }
    .label-user-status-invited { 
      @apply label-warning-inverted;
    }
    .label-user-status-pending { 
      @apply label-warning-inverted;
    }
    .label-user-status-accepted { 
      @apply label-success-inverted;
    }
    .label-user-status-declined { 
      @apply label-danger-inverted;
    }

/* miscellanous labels */
    .label-active,
    .label-yes {
      @apply label-success-inverted;
      @apply label-bold;
      @apply label-short;
      @apply uppercase;
      .label-muted {
        @apply label-success;
        @apply label-border;
      }
    }

    .label-active-muted,
    .label-yes-muted {
      @apply label-success;
      @apply label-bold;
      @apply label-short;
      @apply uppercase;
      @apply label-border;
    }

    .label-inactive,
    .label-no {
      @apply label-danger-inverted;
      @apply label-bold;
      @apply label-short;
      @apply uppercase;
      .label-muted {
        @apply label-danger;
        @apply label-border;
      }
    }

    .label-inactive-muted,
    .label-no-muted {
      @apply label-danger;
      @apply label-bold;
      @apply label-short;
      @apply uppercase;
      @apply label-border;
    }

    .label-status-ended {
      @apply label-status-pending;
    }

    .text-attending {
        @apply text-white;
    }

    .text-waitlist {
        @apply text-white;
    }

    .text-info {
        @apply text-blue-800;
    }

    .text-success {
        @apply text-green-800;
    }

    .text-warning {
        @apply text-yellow-800;
    }

    .text-danger {
        @apply text-red-800;
    }

    .text-disabled {
        @apply text-gray-800;
    }

    .text-info-inverted {
        @apply text-blue-100;
    }

    .text-success-inverted {
        @apply text-green-100;
    }

    .text-warning-inverted {
        @apply text-yellow-100;
    }

    .text-danger-inverted {
        @apply text-red-100;
    }

    .text-disabled-inverted {
        @apply text-gray-200;
    }

    [type="checkbox"], [type="radio"] {
        @apply appearance-none;
        @apply p-0;
        @apply m-1;
        @apply inline-block;
        @apply align-middle;
        @apply bg-origin-border;
        @apply select-none;
        @apply flex-shrink-0;
        @apply w-5;
        @apply h-5;
        @apply text-baseBlue;
        @apply bg-white;
        @apply border-gray-300;
        @apply border-1;
        @apply border-solid;
        @apply rounded-md;
    }

    [type="checkbox"]:checked, [type="radio"]:checked {
        @apply border-transparent;
        @apply bg-current;
        background-size: 100% 100%;
        @apply bg-center;
        @apply bg-no-repeat;
    }

    [type="checkbox"]:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }

    [type="radio"]:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    }

    [type="checkbox"]:focus, [type="radio"]:focus {
        @apply ring-2;
        @apply ring-offset-2;
        @apply ring-offset-white;
        @apply ring-baseBlue;
    }

    .frm-row {
        &:first-of-type {
            .frm-section {
                .frm-section-label {
                    @apply pt-0;
                }
            }
        }
        .frm-container {
            @apply bg-white;
            @apply shadow-clubster;
            @apply rounded-md;
            @apply mb-6;
            @apply p-4;
        }

        .frm-section {
            .frm-section-label {
                @apply text-lg;
                @apply text-gray-700;
                @apply font-semibold;
                @apply pb-3;
                @apply pt-3;
                @apply border-b-1;
                @apply border-solid;
                @apply border-gray-200;
            }
        }

        .frm-row {
            @apply rounded-md;
            @apply overflow-hidden;

            &:focus,
            &:hover {
                @apply bg-lightBlue;
                @apply transition-colors;
            }
        }

        .submit-row,
        .input-row {
            @apply flex;
            @apply flex-row;
            @apply p-4;
            @apply items-start;

            .input-row-label {
                @apply w-48;
                @apply flex-none;
                @apply pr-2;
                @apply py-2;
            }

            .input-row-input {
                @apply flex-grow;
                @apply pl-1;
            }

            input[type=email],
            input[type=text],
            input[type=date],
            input[type=time],
            input[type=password],
            input[type=file],
            textarea,
            button[type=submit],
            button[type=button],
            select,
            {
                &:not(input[type=file]),
                &:not(button[type=submit]) {
                    @apply border-solid;
                    @apply border-1;
                    @apply border-gray-300;
                }

                &:is(button[type=button]),
                &:is(button[type=submit]) {
                    @apply bg-baseBlue;
                    @apply text-white;
                    @apply border-solid;
                    @apply border-1;
                    @apply border-baseBlue;

                    &:hover {
                        @apply transition-colors;
                        @apply bg-lightBlue;
                        @apply text-baseBlue;
                    }
                }

                @apply p-2;
                @apply px-4;
                @apply w-full;
                /* @apply rounded-sm; */
                @apply h-10;

                &:focus {
                    @apply transition-all;
                    @apply ring-2;
                    @apply ring-offset-2;
                    @apply ring-offset-white;
                    @apply ring-baseBlue;
                }
            }

            textarea {
                @apply min-h-24;
            }
        }

        .input-row {
            @apply border-b-1;
            @apply border-solid;
            @apply border-gray-200;
        }

        .error-row {
            .frm-error {
                @apply text-red-900;
                @apply border-solid;
                @apply border-1;
                @apply border-red-900;
                @apply rounded-sm;
                @apply bg-red-100;
                @apply text-center;
                @apply p-2;
            }
        }

        .warning-row {
            .frm-warning {
                @apply text-yellow-900;
                @apply border-solid;
                @apply border-1;
                @apply border-yellow-900;
                @apply rounded-sm;
                @apply bg-yellow-100;
                @apply text-center;
                @apply p-2;
            }
        }

        label[data-visibility] {
            display: block;
            position: relative;
        }

        label[data-visibility="Private"] {
            &:before {
                font-family: "Font Awesome 5 Free";
                content: '\f070';
                @apply inline-block;
                @apply align-middle;
                @apply absolute;
                @apply right-2;
                @apply text-gray-300;
            }
        }

        label[data-visibility="Public"] {
            &:before {
                font-family: "Font Awesome 5 Free";
                content: '\f06e';
                @apply inline-block;
                @apply align-middle;
                @apply absolute;
                @apply right-2;
                @apply text-gray-300;
            }
        }
    }

    .event-date-card-container {
        display: flex;
        flex-wrap: wrap;
        padding: 4px;

        &.small {
            display: block;
            padding: 0px;

            .event-date-card {
                margin: 0px;
                line-height: 16px;
                .event-date-card-date {
                    font-size: 36px;
                }
            }
        }

        //justify-content: center;

        .event-date-card {
            width: 120px;
            padding: 6px 1px;
            background: #95b5d4;
            color: #fff;
            border-radius: 6px;
            margin: 12px;
            line-height: 23px;

            &.event-attending {
                @apply bg-green-700;
                @apply border-green-700;
            }

            &.event-onwaitlist {
                @apply bg-yellow-700;
                @apply border-yellow-700;
            }

            .event-date-card-month {
                text-align: center;
                font-weight: bold;
                letter-spacing: 2px;
                font-size: 18px;
            }

            .event-date-card-date {
                font-size: 42px;
                font-weight: bold;
                text-align: center;
                &.small-date {
                    font-size: 32px;
                }
                margin-top: 6px;
                margin-bottom: 6px;
            }

            .event-date-card-time {
                text-align: center;
                font-size: 12px;
            }
        }
    }

    .event-date-card {
        width: 120px;
        padding: 6px 1px;
        background: #95b5d4;
        color: #fff;
        border-radius: 6px;
        margin: 12px;
        line-height: 23px;

        .event-date-card-month {
            text-align: center;
            font-weight: bold;
            letter-spacing: 2px;
            font-size: 18px;
        }

        .event-date-card-date {
            font-size: 42px;
            font-weight: bold;
            text-align: center;
            &.small-date {
                font-size: 28px;
            }
            margin-top: 6px;
            margin-bottom: 6px;
        }

        .event-date-card-time {
            text-align: center;
            font-size: 12px;
        }
    }

    .message {
        @apply p-4;
        @apply m-2;
        @apply text-center;
        @apply border-1;
        @apply border-solid;
        @apply rounded-md;
        &.info {
            @apply bg-lightBlue;
            @apply border-baseBlue;
            @apply text-baseBlue;
        }
        &.attention {
            @apply bg-yellow-100;
            @apply border-yellow-700;
            @apply text-yellow-700;
        }
        &.warning {
            @apply bg-red-100;
            @apply border-red-700;
            @apply text-red-700;
        }
        &.success {
            @apply bg-green-100;
            @apply border-green-700;
            @apply text-green-700;
        }
    }

    @import "tailwind/layout";
    @import "tailwind/buttons";
    @import "tailwind/callout";
    @import "tailwind/post";
    @import "tailwind/event";
    @import "tailwind/rsvp";
    @import "tailwind/comments";
    //@import "tailwind/floating_navigation";
    @import "tailwind/friends";
}
